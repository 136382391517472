export default function PasswordVerify() {
    return(
        <div
            style={{
                textAlign: 'center',
                margin: '10% 10% 10% 10%',
                color: 'rgb(80, 80, 80)',
            }}
        >
            <h1 className='activation-text'>
                VERIFICATI EMAIL-UL PENTRU RESETAREA PAROLEI!
            </h1>
        </div> 
    )
}