export default function ActivationSuccess() {
    return(
        <div
            style={{
                textAlign: 'center',
                margin: '10% 10% 10% 10%',
                color: 'rgb(80, 80, 80)',
            }}
        >
            <h1 className='activation-text'>
                ATI ACTIVAT CU SUCCES CONTUL!
            </h1>
        </div>
    )
}